@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {
  * {
    @apply focus:outline-none;
    @apply focus-visible:outline outline-1 focus-visible:outline-2 outline-offset-2 focus-visible:outline-primary-a-8;
  }

  [type="text"]:focus-visible,
  input:where(:not([type])),
  [type="email"]:focus-visible,
  [type="url"]:focus-visible,
  [type="password"]:focus-visible,
  [type="number"]:focus-visible,
  [type="date"]:focus-visible,
  [type="datetime-local"]:focus-visible,
  [type="month"]:focus-visible,
  [type="search"]:focus-visible,
  [type="tel"]:focus-visible,
  [type="time"]:focus-visible,
  [type="week"]:focus-visible,
  [multiple]:focus-visible,
  textarea:focus-visible,
  select:focus-visible,
  div[data-focus-within="true"] {
    @apply outline outline-2 outline-offset-0 outline-primary-a-8 border-transparent ring-0 ring-offset-0;
  }
}

:root {
  --sphere-base-size: 24vw;
}
.sphere {
  border-radius: 50%;
  width: calc(var(--sphere-base-size) * 1);
  height: calc(var(--sphere-base-size) * 1);
  box-shadow:
    0px 15px 20px rgba(0, 0, 0, 0.4),
    inset 0px 0px 15px rgba(255, 255, 255, 0.4),
    inset 0px 10px 30px rgba(0, 0, 0, 0.5);
  z-index: -10;
}

.sphere1 {
  --sphere-base-size: 10vw;
}

.sphere2 {
  --sphere-base-size: 12vw;
}

.sphere3 {
  --sphere-base-size: 15vw;
}

.grid-background {
  background-size: 20px 20px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  height: 100%;
  width: 100%;
  opacity: 0.4;
  transform: perspective(50vh) rotateX(60deg) translateZ(10px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -20;
}

.dark .grid-background {
  background-image: linear-gradient(to right, rgba(235, 224, 224, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(235, 224, 224, 0.1) 1px, transparent 1px);
}

.react-aria-Slider {
  &[data-orientation="vertical"] {
    height: 150px;
    display: block;

    .react-aria-Label,
    .react-aria-SliderOutput {
      display: none;
    }

    .react-aria-SliderTrack {
      height: 100%;
      top: 6px;
      bottom: 6px;

      &:before {
        width: 3px;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .react-aria-SliderThumb {
      left: 50%;
    }
  }
}
